import request from "./request.js";

const devOrder= {
    //生产订单列表
    getDevList(params) {
        return request({
            url: '/product/list',
            method: 'post',
            data: params,
        })
    },
}
export default devOrder