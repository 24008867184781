import request from "./request.js";

const fonurm= {
    //论坛列表
    list(params) {
        return request({
            url: '/discus/list',
            method: 'post',
            data: params,
        })
    },
    //发送帖子
    send(params) {
        return request({
            url: '/discus/add',
            method: 'post',
            data: params,
        })
    },
    //评论帖子
    comment(params) {
        return request({
            url: '/discus/comment',
            method: 'post',
            data: params,
        })
    },
    //删除话题
    delMain(params) {
        return request({
            url: '/discus/del',
            method: 'post',
            data: params,
        })
    },
    //删除评论回复
    delCom(params) {
        return request({
            url: '/discus/del_comment',
            method: 'post',
            data: params,
        })
    },
}
export default fonurm