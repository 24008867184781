import request from "./request.js";

const docMana = {
    //医生
    list(params) {
        return request({
            url: '/doctor/list',
            method: 'post',
            data: params,
        })
    },
    //新增
    add(params) {
        return request({
            url: '/doctor/add',
            method: 'post',
            data: params,
        })
    },
    //编辑
    edit(params) {
        return request({
            url: '/doctor/edit',
            method: 'post',
            data: params,
        })
    },
    //删除
    del(params) {
        return request({
            url: '/doctor/del',
            method: 'post',
            data: params,
        })
    }, 
    //审核订单列表
    inspcets(params) {
        return request({
            url: '/inspect/list',
            method: 'post',
            data: params,
        })
    },
}

export default docMana