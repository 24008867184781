<template>
  <div style="margin-top: 0.1vw; text-align: right">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      :page-sizes="[5, 10, 20]"
      layout="total, prev, pager, next"
      :total="total"
    ></el-pagination>
  </div>
</template>  
   
 <script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  total: { type: Number, required: true },
  current: { type: Number, required: true },
  pageSize: { type: Number, required: true },
});

const emit = defineEmits(["changeSize", "changeCurrent"]);

function handleSizeChange(val) {
  emit("changeSize", val);
}

function handleCurrentChange(val) {
  emit("changeCurrent", val);
}
</script>
<style lang='scss' scoped>
@mixin hi() {
  height: 35px;
}

::v-deep .el-pagination.is-background .btn-prev {
  height: 30px;
  border: 1px solid #c0c4cc;
}
::v-deep .el-pagination.is-background .btn-next {
  height: 30px;
  border: 1px solid #c0c4cc;
}
::v-deep .el-pagination__editor.el-input .el-input__inner {
  height: 30px;
}
::v-deep .el-input__inner:focus {
  border-color: #16c2b2;
}
::v-deep .el-pager li {
  min-width: 30px !important;
  height: 30px;
  line-height: 30px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #16c2b2;
}

::v-deep .el-pagination span:not([class*="suffix"]),
.el-pagination button {
  font-size: 14px;
}
</style>