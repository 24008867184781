import request from "./request.js";

const caseInfo= {
    //添加疗程
    addTreat(params) {
        return request({
            url: '/course/add',
            method: 'post',
            data: params,
        })
    },
    //添加记录
    addRecord(params) {
        return request({
            url: '/record/add',
            method: 'post',
            data: params,
        })
    },
   //删除疗程
    delTreat(params) {
        return request({
            url: '/course/del',
            method: 'post',
            data: params,
        })
    },
//编辑疗程
    setTreat(params) {
        return request({
            url: '/course/edit',
            method: 'post',
            data: params,
        })
    },
    //编辑记录
    setRecord(params) {
        return request({
            url: '/record/edit',
            method: 'post',
            data: params,
        })
    },
    //删除记录
    delRecord(params) {
        return request({
            url: '/record/del',
            method: 'post',
            data: params,
        })
    },
    //档案编辑
   
    editDoc(params) {
        return request({
            url: '/course/dossier',
            method: 'post',
            data: params,
        })
    },
    //上传图片
    upLoad(params) {
        return request({
            url: '/record/addpic',
            method: 'post',
            data: params,
        })
    },
    //获取对应记录
    getRecord(params) {
        return request({
            url: '/record/show',
            method: 'post',
            data: params,
        })
    },
    //档案删除
    editDel(params) {
        return request({
            url: '/course/dossier_del',
            method: 'post',
            data: params,
        })
    },
    //图片手动分类
    picType(params) {
        return request({
            url: '/record/editpic',
            method: 'post',
            data: params,
        })
    },
    //删除图片
    
    delPic(params) {
        return request({
            url: '/record/delpic',
            method: 'post',
            data: params,
        })
    },
}
export default caseInfo